import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import 'ag-charts-enterprise';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';

LicenseManager.setLicenseKey(
  'ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2'
);

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, ToastModule, ProgressSpinnerModule, SidebarModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterContentChecked {
  protected changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  // protected websocketService: WebSocketService = inject(WebSocketService);

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
