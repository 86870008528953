import {Component, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Router, RouterLink} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {LayoutService} from '../../services/layout.service';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonDirective} from 'primeng/button';
import {Ripple} from 'primeng/ripple';
import {InputTextModule} from 'primeng/inputtext';
import {ToastModule} from 'primeng/toast';

@Component({
  selector: 'app-login',
  imports: [FormsModule, CommonModule, CheckboxModule, ButtonDirective, Ripple, InputTextModule, ToastModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  email: string = '';
  password: string = '';
  loading: boolean = false;

  private layoutService: LayoutService = inject(LayoutService);
  private router: Router = inject(Router);
  private authService: AuthService = inject(AuthService);
  private notificationService: NotificationService = inject(NotificationService);

  constructor() {}

  onSubmit() {
    this.loading = true;
    this.authService.login(this.email, this.password).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.authService.setAuth(res);
        this.router.navigateByUrl('/analytics');
      },
      error: (err: any) => {
        this.notificationService.showError('Error', err.error.message);
        console.error('Err:', err);
        this.loading = false;
      },
      complete: () => console.info('auth success')
    });
    return true;
  }

  get dark(): boolean {
    return this.layoutService.config().colorScheme !== 'light';
  }
}
