import { AuthService } from '../services/auth.service';
import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  private roles: string[] = ['admin'];

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.roles.includes(this.authService.getUserRole())) {
      return true;
    } else {
      this.router.navigateByUrl('/overview');
      return false;
    }
  }

  checkRole() {
    return this.roles.includes(this.authService.getUserRole());
  }
}
