import {Component, inject} from '@angular/core';
import {ButtonDirective} from 'primeng/button';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {Ripple} from 'primeng/ripple';
import {ToastModule} from 'primeng/toast';
import {NotificationService} from '../../services/notification.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register',
  imports: [
    ButtonDirective,
    FormsModule,
    InputTextModule,
    Ripple,
    ToastModule
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {
  clickup_key: string = '';
  password: string = '';
  confirm_password: string = '';
  loading: boolean = false;

  private router: Router = inject(Router);
  private notificationService: NotificationService = inject(NotificationService);
  private authService: AuthService = inject(AuthService);

  onSubmit() {
    if (this.confirm_password !== this.password) {
      this.notificationService.showError('Error', 'Passwords do not match');
      return false;
    }
    this.loading = true;
    this.authService.register(this.clickup_key, this.password).subscribe({
      next: (res: any) => {
        this.notificationService.showSuccess('Success', 'User successfully registered');
        this.loading = false;
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 2000);
      },
      error: (err: any) => {
        this.notificationService.showError('Error', err.error.message);
        console.error('Err:', err);
        this.loading = false;
      },
      complete: () => {
        console.info('register success');
      }
    });
    return true;
  }
}
