import {Component, inject} from '@angular/core';
import {ButtonDirective} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {Ripple} from "primeng/ripple";
import {ToastModule} from "primeng/toast";
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
    imports: [
        ButtonDirective,
        FormsModule,
        InputTextModule,
        Ripple,
        ToastModule
    ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  email: string = '';
  loading: boolean = false;

  private router: Router = inject(Router);
  private notificationService: NotificationService = inject(NotificationService);
  private authService: AuthService = inject(AuthService);

  onSubmit() {
    this.loading = true;
    this.authService.forgotPassword(this.email).subscribe({
      next: (res: any) => {
        this.notificationService.showSuccess('Success', 'A password reset link has been successfully sent to your email.');
        this.loading = false;
      },
      error: (err: any) => {
        this.notificationService.showError('Error', err.error.message);
        console.error('Err:', err);
        this.loading = false;
      },
      complete: () => {
        console.info('register success');
      }
    });
    return true;
  }
}
